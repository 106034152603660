<template>
  <div class="add-data-contaner">
      <!-- 设备信息s -->
      <div class="msg-box mt24 b">
        <p class="line1">
          <span class="id f20">{{ deviceDetail.deviceNumber }}</span>
          <span class="num f16"
            >No.{{
              deviceDetail.systemNumber ? deviceDetail.systemNumber : "-"
            }}</span
          >
        </p>
        <p class="line2">
          <span class="f16 c3"
            >设备类型：{{
              deviceDetail.deviceTypeParentName
                ? deviceDetail.deviceTypeParentName +
                  "-" +
                  deviceDetail.deviceTypeName
                : "-"
            }}</span
          >
          <span class="f16 c3"
            >设备品牌：{{
              deviceDetail.brandName ? deviceDetail.brandName : "-"
            }}</span
          >
          <span class="f16 c3"
            >出厂日期：{{
              deviceDetail.productionDate ? deviceDetail.productionDate : "-"
            }}</span
          >
          <span class="f16 c3"
            >设备型号：{{
              deviceDetail.deviceModel ? deviceDetail.deviceModel : "-"
            }}</span
          >
          <span class="f16 c3"
            >控制系统：{{
              deviceDetail.controlName ? deviceDetail.controlName : "-"
            }}</span
          >
        </p>
      </div>
      <!-- 设备信息e -->
      <!-- 添加设备资料 s -->
      <div class="mt24 add">
        <button>
          <img src="@/assets/images/home/add.png" alt="" />
          <span class="cf f14">
            <router-link :to="{ name: 'add-device-data', query: { id: id } }"
              >添加设备资料</router-link
            >
          </span>
        </button>
      </div>
      <!-- 添加设备资料 e -->
      <!-- 表格展示 start -->
      <el-row class="table-box mt20">
        <el-table
          :data="tableData"
          border
        >
          <el-table-column prop="num" label="序号" min-width="50"></el-table-column>
          <el-table-column
            prop="data"
            align="center"
            label="设备资料"
            min-width="120"
          ></el-table-column>
          <el-table-column align="center" label="图片" prop="imgs" min-width="120">
            <template slot-scope="{ row }">
              <div v-if="row.fileType == 2">-</div>
              <div
                v-else
                v-for="(item, index) in row.imgs.split(',')"
                :key="index"
                style="display: inline-block"
              >
                <img class="table-img" :src="item" alt="" />
              </div>
            </template>

            <!-- <div>-</div> -->
          </el-table-column>
          <el-table-column
            class-name="blue"
            align="center"
            prop="time"
            label="上传时间"
            min-width="100"
            > <template slot-scope="{ row }">{{
              row.time || "-"
            }}</template></el-table-column
          >
          <el-table-column
            class-name="blue"
            align="center"
            label="操作"
            min-width="120"
          >
            <template slot-scope="scope">
              <!-- 下载文件 -->
              <span
                @click="handleEdit(scope.$index, scope.row)"
                class="f14 see p b"
              >
                <span class="edit">下载文件</span>
              </span>
              <span class="line"></span>
              <!-- 删除 -->
              <span
                @click="handleDel(scope.$index, scope.row)"
                class="f14 see p b"
              >
                <span class="del">删除</span>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 表格展示 end -->
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="listTotal"
        :page-size="pageSize"
        :current-page="page"
        @current-change="pageChange"
        @prev-click="prevChange"
        @next-click="nextChange"
      >
      </el-pagination>
  </div>
</template>

<script>
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  name: "YoufujichuangRepairrecord",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
  },
  data() {
    return {
      tableData: [], //表格数据
      // 分页相关数据
      page: 1,
      pageSize: 5,
      listTotal: 0,
      id: "", //设备id

      deviceDetail: [], //设备详情
    };
  },

  mounted() {},
  created() {
    this.id = this.$route.query.id;

    // 设备文件-分页列表查询
    this.getDeviceList();

    // 通过id查询设备信息
    this.getDeviceRes();
  },

  methods: {
    // 通过id查询设备信息
    getDeviceRes() {
      let paramData = {
        id: this.id,
      };
      this.$API
        .getDeviceRes(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.deviceDetail = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 设备文件-分页列表查询
    getDeviceList() {
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
        deviceId: this.id,
      };
      this.$API
        .getDeviceList(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.listTotal = Number(res.data.total);
            //         {
            //   num: "1",
            //   data: "文档名称B.pdf",
            //   imgs: "fdsfdsf",
            //   time: "2021/10/10",
            // },
            this.tableData = [];
            res.data.records.forEach((item, index) => {
              let imgs = item.imgs ? item.fileUrl.split(",") : [];
              this.tableData.push({
                num: (this.page - 1) * this.pageSize + index + 1,
                data: item.fileName, //系统编号
                imgs: item.fileUrl, //图片
                time: item.createTime,
                fileType: item.fileType, //1 图片     2文件
                id: item.id,
                fileUrl: item.fileUrl,
              });
            });
            console.log(this.tableData, "tabledata");
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 编辑
    handleEdit(index, item) {
      console.log("下载");
      // console.log(row);
      console.log(item);
      let url = item.fileUrl;
      let houzhuiArr = url.split(".");
      let houzhui = houzhuiArr[houzhuiArr.length - 1];
      if (houzhui == "pdf") {
        console.log("下载pdf文件");
        this.fileLinkToStreamDownload(url);
      } else {
        console.log(url, "url");
        let urlArr = url.split(",");
        console.log(urlArr);
        var arrayLength = urlArr.length;
        var i = 0;
        window.open(urlArr[i], "_wnd" + i);
        if (i < arrayLength) {
          i += 1;
          window.open(urlArr[i], "_wnd" + i);
        }
        if (i < arrayLength) {
          i += 1;
          window.open(urlArr[i], "_wnd" + i);
        }
        // for (var i = 0; i < arrayLength; i++) {
        //   window.open(urlArr[i], "_wnd" + i);
        // }
        // urlArr.forEach((item) => {
        //   console.log(item);
        //   window.open(item, "_self");
        // });
        // window.open(url, "_self");
      }
      return;
    },
    // 下载pdf方法
    fileLinkToStreamDownload(url) {
      let fileName = this.getDay();
      let reg =
        /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
      if (!reg.test(url)) {
        throw new Error("传入参数不合法,不是标准的文件链接");
      } else {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.setRequestHeader("Content-Type", `application/pdf`);
        xhr.responseType = "blob";
        let that = this;
        xhr.onload = function () {
          if (this.status == 200) {
            //接受二进制文件流
            var blob = this.response;
            that.downloadExportFile(blob, fileName);
          }
        };
        xhr.send();
      }
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      // downloadElement.download =
      //   tagFileName +
      //   //下载后文件名
      //   document.body.appendChild(downloadElement);
      downloadElement.download = tagFileName;
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    getDay() {
      let time = new Date(),
        year = time.getFullYear(),
        month = time.getMonth() + 1,
        day = time.getDate(),
        timeStem = time.getTime();
      return `${year}/${month}/${day}/${timeStem}.pdf`;
    },
    // 下载pdf文件方法结束
    // 删除
    handleDel(index, item) {
      // console.log(index, item);
      let paramData = {
        fileId: item.id,
      };
      this.$alert("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showCancelButton: true,
        callback: (action) => {
          // 确定  confirm  取消 cancel
          if (action == "confirm") {
            this.$API
              .delDeviceFile(paramData)
              .then((res) => {
                if (res.code == 1) {
                  this.$message({
                    message: "删除成功",
                    type: "success",
                    duration: 500,
                  });
                  // 设备文件-分页列表查询
                  this.getDeviceList();
                } else {
                  this.$message.info(res.msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        },
      });
    },
    // 当前页码改变
    pageChange(page) {
      this.page = page;
      this.tableData = [];
      this.getDeviceList();
    },

    // 点击上一页
    prevChange() {},
    // 点击下一页
    nextChange() {},
    // 返回
    toDevice() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.add-data-contaner {
  // 分頁
  .el-pagination {
    text-align: right;
    margin-bottom: 46px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }

  .table-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .edit {
    color: #1577fc;
  }
  .del {
    color: #ff4656;
  }
  .line {
    width: 0px;
    height: 13px;
    border: 1px solid #c6ccda;
    margin: 10px;
  }
  width: 100%;
  .border {
    border-bottom: 1px solid #eeeeee;
  }
  .right {
    button {
      width: 96px;
      height: 40px;
      background: #efefef;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  //   设备信息
  .msg-box {
    // width: 1120px;
    min-height: 119px;
    background: #ffeff0;
    border-radius: 16px 16px 16px 16px;
    border: 1px solid #ed1b23;
    padding: 24px 20px 20px 20px;
    .line1 {
      padding-bottom: 17px;
      border-bottom: 1px solid #f4c8cb;
      .id {
        color: #ff4656;
        margin-right: 12px;
      }
      .num {
        color: #99989c;
      }
    }
    .line2 {
      margin-top: 16px;
      span {
        margin-right: 43px;
      }
    }
  }
  //   添加设备资料
  .add {
    button {
      width: 149px;
      height: 40px;
      background: #1577fc;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  // 表格
  .table-box {
    margin-bottom: 20px;
    .handle {
      height: 39px;
      ul {
        height: 39px;
        line-height: 39px;
        display: flex;
        align-items: center;
      }
      li {
        float: left;
        font-size: 14px;
        font-weight: 400;
        // color: #2a89ff;
        line-height: 22px;
      }
      .line {
        width: 0px;
        height: 13px;
        border: 1px solid #c6ccda;
        margin: 12px 12px;
      }
    }
  }
}
</style>